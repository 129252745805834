import React from "react";
import Block from "../../components/ui/Block";
import BlockTitle from "../../components/ui/BlockTitle";
import BlockBody from "../../components/ui/BlockBody";
import BlockLink from "../../components/ui/BlockLink";
import BlockLinkLabel from "../../components/ui/BlockLinkLabel";

const Home: React.FC = () => {
  return (
    <div>
      <h1>Sécurité</h1>
      <Block>
        <BlockTitle>Connexion à votre compte Tenoco</BlockTitle>
        <BlockBody>
          <p>Il est conseillé de changer régulièrement son mot de passe.</p>
          <p>
            Votre nom d'utilisateur ainsi que votre adresse de courriel ne
            peuvent pas être modifiés.
          </p>
        </BlockBody>
        <BlockLink to="/security/password">
          <BlockLinkLabel>Mot de passe</BlockLinkLabel>
          ••••••••••
        </BlockLink>
      </Block>

      <Block>
        <BlockTitle>Mot de passe d'application</BlockTitle>
        <BlockBody>
          Générez des mots de passes uniques pour vous connecter à votre compte
          depuis des applications tierces ne supportant pas l'authentification
          forte de Tenoco.
        </BlockBody>
        <BlockLink to="/security/apppasswords">
          Gérer mes mots de passe d'application
        </BlockLink>
      </Block>
    </div>
  );
};

export default Home;
