import { State } from "./state";
import {
  Action,
  FETCH_LIST,
  GENERATE_PASSWORD,
  DELETE_PASSWORD,
  CLEAR,
} from "./actions";

const initialState = { loading: false };

export default (state: State = initialState, action: Action): State => {
  if (action.type === FETCH_LIST) {
    if (action.status === "success") {
      return {
        ...state,
        passwords: action.payload,
        error: undefined,
        loading: false,
      };
    } else if (action.status === "error") {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    } else {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
  }

  if (action.type === GENERATE_PASSWORD) {
    if (action.status === "success") {
      const { password, ...rest } = action.payload;
      return {
        ...state,
        passwords: [rest, ...(state.passwords || [])],
        generated: action.payload,
        error: undefined,
        loading: false,
      };
    } else if (action.status === "error") {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    } else {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
  }

  if (action.type === DELETE_PASSWORD) {
    if (action.status === "success") {
      return {
        ...state,
        passwords: state.passwords?.filter((p) => p.id !== action.payload),
        error: undefined,
        loading: false,
      };
    } else if (action.status === "error") {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    } else {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
  }

  if (action.type === CLEAR) {
    return {
      ...state,
      generated: undefined,
    };
  }

  return state;
};
