import React from "react";

const Label: React.FC = (props) => {
  return (
    <label className="block font-bold text-right mb-0 pr-4">
      {props.children}
    </label>
  );
};

export default Label;
