import { isLeft } from "fp-ts/es6/Either";
import { useSelector, shallowEqual } from "react-redux";
import {
  ObjectSerializer,
  SettingsRequest,
} from "@oryd/kratos-client/dist/model/models";

import * as r from "./routes";
import { State } from "../store/state";

export type Options = {
  base?: URL;
  token?: string;
};

const buildRoute = <Req, Res>(route: r.Route<Req, Res>) => async ({
  req,
  options,
}: {
  req: Req;
  options?: Options;
}): Promise<Res> => {
  let path: string;
  if (typeof route.path === "function") {
    path = route.path(req);
  } else {
    path = route.path;
  }

  const endpoint = new URL(path, options?.base);
  const headers: Record<string, string> = {};
  const init: RequestInit = { headers };

  if (options?.token) {
    headers["Authorization"] = `Bearer ${options.token}`;
  }

  if (route.method !== "GET") {
    init.method = route.method;
    if (route.request) {
      const body = route.request.encode(req);
      headers["Content-Type"] = "application/json";
      init.body = JSON.stringify(body);
    }
  }
  const res = await fetch(endpoint.href, init);
  if (res.status >= 400) {
    throw new Error();
  }
  const body = await res.json();
  const result = route.response.decode(body);

  if (isLeft(result)) {
    // TODO: error reporting
    console.log(result.left);
    throw new Error();
  }

  return result.right;
};

export const generatePassword = buildRoute(r.GeneratePassword);
export const listPasswords = buildRoute(r.ListPassword);
export const deletePassword = buildRoute(r.DeletePassword);

const BASE_URL = new URL(window.APP_CONFIG.apiBaseURL);

export const useAuthenticatedOptions = (): Options =>
  useSelector(
    (s: State) => ({ token: s.auth.user?.access_token, base: BASE_URL }),
    shallowEqual
  );

// @TODO: change that with the right URL
export const SettingsRequestUrl = "/personal-info";

export const getSettingsRequest = async (
  requestId: string
): Promise<SettingsRequest> => {
  const req = await fetch(
    `${window.APP_CONFIG.authority}/self-service/browser/flows/requests/settings?request=${requestId}`,
    {
      mode: "cors",
      credentials: "include",
      headers: { Accept: "application/json" },
    }
  );

  if (req.status < 200 || req.status >= 300) {
    throw new Error(`invalid status ${req.status} ${req.statusText}`);
  }

  const body = await req.json();
  const settings: SettingsRequest = ObjectSerializer.deserialize(
    body,
    "SettingsRequest"
  );

  return settings;
};
