import { intoThunk, ThunkAction } from "../utils";
import {
  SavedPassword,
  BasePassword,
  GeneratedPassword,
} from "../../api/models";
import * as api from "../../api";

export const CLEAR = "apppasswords/CLEAR";
export const FETCH_LIST = "apppasswords/FETCH_LIST";
export const GENERATE_PASSWORD = "apppasswords/GENERATE_PASSWORD";
export const DELETE_PASSWORD = "apppasswords/DELETE_PASSWORD";

type FetchList = ThunkAction<typeof FETCH_LIST, SavedPassword[]>;
export const fetchList = (options: api.Options) =>
  intoThunk(
    FETCH_LIST,
    api
      .listPasswords({ req: undefined, options })
      .then((response) => response.passwords)
  );

type GeneratePassword = ThunkAction<
  typeof GENERATE_PASSWORD,
  GeneratedPassword
>;
export const generatePassword = (
  password: BasePassword,
  options: api.Options
) =>
  intoThunk(
    GENERATE_PASSWORD,
    api.generatePassword({ req: password, options })
  );

type DeletePassword = ThunkAction<typeof DELETE_PASSWORD, {}>;
export const deletePassword = (id: number, options: api.Options) =>
  intoThunk(
    DELETE_PASSWORD,
    api.deletePassword({ req: id, options }).then(() => id)
  );

export type Clear = { type: typeof CLEAR };
export const clear = () => ({ type: CLEAR });

export type Action = FetchList | GeneratePassword | DeletePassword | Clear;
