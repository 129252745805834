import React, { useState } from "react";
import { FormField } from "@oryd/kratos-client/dist/model/formField";
import Label from "./Label";
import Select from "./Select";
import Input from "./Input";

const genderToString = (gender?: string) => {
  switch (gender) {
    case "female":
      return "Femme";
    case "male":
      return "Homme";
  }

  return "Non précisé";
};

const labelFromName = (name: string) => {
  switch (name) {
    case "traits.gender":
      return "Sexe";
    case "traits.name.first":
      return "Prénom";
    case "traits.name.last":
      return "Nom";
    case "password":
      return "Mot de passe";
  }

  return name;
};

type RenderUserSettingsField = {
  field: FormField;
  hidden: boolean;
};

const RenderUserSettingsField: React.FC<RenderUserSettingsField> = (
  props: RenderUserSettingsField
) => {
  const [value, setValue] = useState(`${props.field.value || ""}`);

  if (props.hidden || props.field.type === "hidden") {
    return <input type="hidden" name={props.field.name} value={`${value}`} />;
  }

  if (props.field.name === "traits.gender") {
    const genders = ["female", "male", ""];

    return (
      <div className="flex items-center mb-6">
        <div className="w-32">
          <Label>{labelFromName(props.field.name)}</Label>
        </div>
        <div>
          <Select
            value={value}
            name={props.field.name}
            onChange={(e) => setValue(e.target.value)}
          >
            {genders.map((g) => (
              <option value={g} key={g}>
                {genderToString(g)}
              </option>
            ))}
          </Select>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center mb-6">
      <div className="w-32">
        <Label>{labelFromName(props.field.name)}</Label>
      </div>
      <div className="w-64">
        <Input
          type={props.field.type}
          name={props.field.name}
          value={`${value}`}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
};

export default RenderUserSettingsField;
