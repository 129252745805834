import React from "react";
import Block from "../../components/ui/Block";
import BlockTitle from "../../components/ui/BlockTitle";
import BlockBody from "../../components/ui/BlockBody";
import BlockLink from "../../components/ui/BlockLink";
import { useSelector } from "react-redux";
import { State } from "../../store/state";
import BlockLinkLabel from "../../components/ui/BlockLinkLabel";

const genderToString = (gender?: string) => {
  switch (gender) {
    case "female":
      return "Femme";
    case "male":
      return "Homme";
  }

  return "Non précisé";
};

const handleUndefined = (info?: string) => {
  if (!info || info === "undefined") return "";
  return info;
};

const handleEmpty = (info?: string) => {
  if (!info) {
    return "Non précisé";
  }

  return info;
};

const Home: React.FC = () => {
  const user = useSelector((s: State) => s.auth.user);
  const name = `${handleUndefined(user?.profile.given_name)} ${handleUndefined(
    user?.profile.family_name
  )}`.trim();
  const gender = user?.profile.gender;

  return (
    <div>
      <h1>Informations personnelles</h1>
      <Block>
        <BlockTitle>Profil</BlockTitle>
        <BlockBody>
          Ces informations représentent votre compte. Cliquez sur une
          information pour l'éditer.
        </BlockBody>
        <BlockLink to="/name">
          <BlockLinkLabel>Nom</BlockLinkLabel>
          {handleEmpty(name)}
        </BlockLink>
        <BlockLink to="/gender">
          <BlockLinkLabel>Sexe</BlockLinkLabel>
          {genderToString(gender)}
        </BlockLink>
        <BlockLink to="/security/password">
          <BlockLinkLabel>Mot de passe</BlockLinkLabel>
          ••••••••••
        </BlockLink>
      </Block>
    </div>
  );
};

export default Home;
