import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SavedPassword } from "../../../api/models";
import { State } from "../../../store/state";
import { useAuthenticatedOptions } from "../../../api";
import {
  fetchList,
  generatePassword,
  deletePassword,
  clear,
} from "../../../store/apppasswords/actions";

import Block from "../../../components/ui/Block";
import BlockTitle from "../../../components/ui/BlockTitle";
import BlockBody from "../../../components/ui/BlockBody";
import BlockLink from "../../../components/ui/BlockLink";

type GeneratedPasswordProps = {
  password: string;
};

const GeneratedPassword: React.FC<GeneratedPasswordProps> = (
  props: GeneratedPasswordProps
) => {
  const password = props.password.match(/.{1,4}/g);
  const passwordRef = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState<boolean>(false);

  const selectPassword = () => {
    if (passwordRef.current === null) return;
    window?.getSelection()?.selectAllChildren(passwordRef.current);
  };

  const copyToClipboard = () => {
    if (passwordRef.current === null) return;
    window?.getSelection()?.selectAllChildren(passwordRef.current);
    document.execCommand("copy");
    setCopied(true);
  };

  return (
    <div className="p-8 rounded bg-gray-200 text-xl text-center font-bold font-mono flex">
      <div className="flex-1" ref={passwordRef} onClick={selectPassword}>
        {password?.map((p, index) => (
          <span className="mx-1" key={index}>
            {p}
          </span>
        ))}
      </div>
      <button onClick={copyToClipboard}>{copied ? "Copié" : "Copier"}</button>
    </div>
  );
};

export const translateServiceName = (name: string) => {
  switch (name) {
    case "LOGIN_TYPE_EMAIL":
      return "Courriel";
    case "LOGIN_TYPE_MATRIX":
      return "Matrix";
  }
  return name;
};

const Apppasswords: React.FC = () => {
  const options = useAuthenticatedOptions();
  const dispatch = useDispatch();
  const loading = useSelector(
    (s: State) => s.apppasswords.loading || s.auth.isLoadingUser
  );
  const error = useSelector((s: State) => s.apppasswords.error);
  const authenticated = useSelector((s: State) => !!s.auth.user);
  let passwords = useSelector((s: State) => s.apppasswords.passwords);

  useEffect(() => {
    if (!loading && !error && !passwords && authenticated) {
      dispatch(fetchList(options));
    }
  }, [loading, error, passwords, authenticated, options, dispatch]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [expiration, setExpiration] = useState("");

  const generated = useSelector((s: State) => s.apppasswords.generated);
  const email = useSelector((s: State) => s.auth.user?.profile?.email);

  const passwordsList = (passwords || []).sort(
    (p1, p2) => p2.creationTime.getTime() - p1.creationTime.getTime()
  );

  const onClear = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(clear());
  };

  const onGenerate = () => {
    if (!loading) {
      if (!email) return;
      dispatch(
        generatePassword(
          {
            type: "LOGIN_TYPE_EMAIL",
            username: email,
            name,
            description,
            expirationTime: expiration ? new Date(expiration) : undefined,
          },
          options
        )
      );
    }
  };

  const onDelete = (id: number) => () => {
    if (!loading) {
      dispatch(deletePassword(id, options));
    }
  };

  return (
    <div>
      <h1>Mot de passe d'application</h1>
      {generated && (
        <Block>
          <BlockTitle>Voici votre mot de passe généré</BlockTitle>
          <BlockBody>
            <p>
              Pour des raisons de sécurité, veillez à n'utiliser ce mot de passe
              que dans une seule application.
            </p>
            <p className="mb-4">
              N'hésitez pas à en générer autant que nécessaire.
            </p>
            <GeneratedPassword password={generated.password} />
            <p className="mt-4">
              Une fois que vous changez de page, ce mot de passe ne vous sera
              plus jamais montré.
            </p>
            <p>
              Une fois qu'une application n'est plus utilisée, veillez à
              supprimer le mot de passe correspondant de la liste.
            </p>
          </BlockBody>
          <BlockLink to="/security/apppasswords" onClick={onClear}>
            Cacher
          </BlockLink>
        </Block>
      )}
      {error ? (
        <Block>
          <BlockTitle>Une erreur est survenue</BlockTitle>
          <BlockBody>
            <pre className="font-mono">
              <code>{error}</code>
            </pre>
          </BlockBody>
        </Block>
      ) : (
        <Block>
          <BlockTitle>Vos mots de passe d'application</BlockTitle>
          <BlockBody>
            <table className="table-auto w-full">
              <thead>
                <tr className="text-left">
                  <th className="p-4">Nom</th>
                  <th className="p-4">Service</th>
                  <th className="p-4">Création</th>
                  <th className="p-4">Expiration</th>
                  <th className="p-4">Supprimer ?</th>
                </tr>
              </thead>
              <tbody>
                {passwordsList.map((p: SavedPassword) => (
                  <tr key={p.id} className="border-b hover:bg-gray-100">
                    <td className="px-4 py-2">
                      <p className="font-bold">{p.name}</p>
                      <p>{p.description}</p>
                    </td>
                    <td className="px-4 py-2">
                      <p className="font-bold">
                        {translateServiceName(p.type)}
                      </p>
                      <p>{p.username}</p>
                    </td>
                    <td className="px-4 py-2">
                      {p.creationTime.toLocaleDateString()}{" "}
                      {p.creationTime.toLocaleTimeString()}
                    </td>
                    <td className="px-4 py-2">
                      {p.expirationTime?.toLocaleDateString()}{" "}
                      {p.expirationTime?.toLocaleTimeString()}
                    </td>
                    <td className="px-4 py-2">
                      <button
                        className="bg-primary hover:opacity-50 text-white font-bold py-2 px-4 rounded"
                        onClick={onDelete(p.id)}
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BlockBody>
          <BlockTitle>Générer un nouveau mot de passe d'application</BlockTitle>
          <BlockBody>
            <form>
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label className="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    Nom<span className="text-red-500">*</span>{" "}
                    <span className="font-normal">(application, …)</span>
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label className="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    Description{" "}
                    <span className="font-normal">(appareil, version, …)</span>
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>

              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label className="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    Service<span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="md:w-2/3">
                  <div className="inline-block relative w-full">
                    <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                      <option value="email">Courriel</option>
                      <option disabled>Matrix (prochainement)</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label className="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    Compte<span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="md:w-2/3">
                  <div className="inline-block relative w-full">
                    <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                      <option value={email}>{email}</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label className="block text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    Expiration{" "}
                    <span className="text-normal">(fuseau horaire UTC)</span>
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    type="datetime-local"
                    value={expiration}
                    onChange={(e) => setExpiration(e.target.value)}
                    placeholder="Format: yyyy-MM-ddThh:mm (ex: 2020-12-04T15:42)"
                  />
                </div>
              </div>

              <div className="md:flex md:items-center">
                <div className="md:w-1/3"></div>
                <div className="md:w-2/3">
                  <button
                    className="shadow bg-primary hover:bg-opacity-75 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={onGenerate}
                  >
                    Générer
                  </button>
                </div>
              </div>
            </form>
          </BlockBody>
        </Block>
      )}
    </div>
  );
};

export default Apppasswords;
