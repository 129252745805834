import React from "react";
import Block from "../components/ui/Block";
import BlockTitle from "../components/ui/BlockTitle";
import BlockBody from "../components/ui/BlockBody";
import BlockLink from "../components/ui/BlockLink";

const Services: React.FC = () => {
  return (
    <div>
      <h1>Services</h1>
      <Block>
        <BlockTitle>Compte de messagerie</BlockTitle>
        <BlockBody>
          Consultez votre courriel, gérez votre agenda et vos contacts.
        </BlockBody>
        <BlockLink to="https://docs.tenoco.net/mail/configuration">
          Configurer mon client de messagerie
        </BlockLink>
      </Block>
      <Block>
        <BlockTitle>Discussion instantanée</BlockTitle>
        <BlockBody>
          Discutez avec vos collègues, amis et proches de manière sécurisée.
        </BlockBody>
        <BlockLink to="https://chat.tenoco.net/">
          Lancer le client web
        </BlockLink>
      </Block>
      <Block>
        <BlockTitle>Tenoco Meet</BlockTitle>
        <BlockBody>
          <p>Vous avez besoin d'organiser une visioconférence ?</p>
          <p>
            Créez un salon et partagez le lien avec toutes les personnes que
            vous souhaitez inviter.
            <br />
            Ces personnes n'auront pas besoin de se créer un compte Tenoco pour
            participer.
          </p>
        </BlockBody>
        <BlockLink to="https://meet.tenoco.net/">
          Accéder à Tenoco Meet
        </BlockLink>
      </Block>
      <Block>
        <BlockTitle>État des services</BlockTitle>
        <BlockBody>Aucune maintenance n'est prévue pour le moment.</BlockBody>
        <BlockLink to="https://status.tenoco.net/">
          Consulter l'état des services
        </BlockLink>
      </Block>
    </div>
  );
};

export default Services;
