import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import RightArrow from "./RightArrow";

type BlockLinkProps = {
  children?: ReactNode;
  to: string;
} & React.HTMLAttributes<HTMLAnchorElement>;

const BlockLink: React.FC<BlockLinkProps> = (props: BlockLinkProps) => {
  const className =
    "block p-4 border-t border-gray-400 text-primary text-opacity-75 hover:text-opacity-100 hover:bg-gray-100 rounded rounded-t-none mb-px flex";

  return props.to.startsWith("http") ? (
    <a
      href={props.to}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      onClick={props.onClick}
    >
      <div className="flex flex-1">{props.children}</div>
      <RightArrow />
    </a>
  ) : (
    <Link to={props.to} className={className} onClick={props.onClick}>
      <div className="flex flex-1">{props.children}</div>
      <RightArrow />
    </Link>
  );
};

export default BlockLink;
