import { Dispatch } from "redux";

export type ThunkAction<T extends string, P> = {
  type: T;
} & (
  | {
      status: "success";
      payload: P;
    }
  | {
      status: "error";
      error: string;
    }
  | { status?: never }
);

export const intoThunk = <T extends string, P>(
  type: T,
  promise: Promise<P>
) => (dispatch: Dispatch<ThunkAction<T, P>>) => {
  dispatch({ type });
  promise.then(
    (payload: P) => dispatch({ type, status: "success", payload }),
    (error: Error) =>
      dispatch({ type, status: "error", error: error.toString() })
  );
};
