import { createUserManager } from "redux-oidc";
import { UserManagerSettings } from "oidc-client";

const base = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

const userManagerConfig: UserManagerSettings = {
  client_id: window.APP_CONFIG.clientID,
  redirect_uri: `${base}/callback`,
  post_logout_redirect_uri: `${base}/post-logout`,
  response_type: "code",
  scope: "openid profile email apppasswords:read apppasswords:write",
  authority: window.APP_CONFIG.authority,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export { userManager };
