import { createStore, compose, applyMiddleware } from "redux";
import { LocationState, History } from "history";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";

import createRootReducer from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = <S = LocationState>(history: History<S>) => {
  const rootReducer = createRootReducer(history);
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );
  return store;
};

export const history = createBrowserHistory();
export default configureStore(history);
