import React from "react";
import { useSelector } from "react-redux";
import { State } from "../../store/state";

import { userManager } from "../../auth";

const LoginBlock: React.FC = () => {
  const user = useSelector((s: State) => s.auth.user);

  const login = () => userManager.signinRedirect();
  const logout = async () => {
    await userManager.revokeAccessToken();
    await userManager.signoutRedirect();
  };

  if (!user) {
    return <button onClick={login}>Connexion</button>;
  }

  return (
    <div>
      {user.profile.given_name} - <button onClick={logout}>Déconnexion</button>
    </div>
  );
};

const Header: React.FC = () => {
  return (
    <nav className="flex items-center justify-between flex-wrap bg-primary p-6">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <span className="font-semibold text-xl tracking-tight">
          Mon compte Tenoco
        </span>
      </div>
      <div className="text-white">
        <LoginBlock />
      </div>
    </nav>
  );
};

export default Header;
