import React from "react";
import { Switch, Route } from "react-router-dom";
import Apppasswords from "./apppasswords";
import Home from "./Home";
import Password from "./password";

const Security: React.FC = () => {
  return (
    <Switch>
      <Route path="/security/apppasswords" component={Apppasswords} />
      <Route path="/security/password" component={Password} />
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default Security;
