import React from "react";
import ReactDOM from "react-dom";
import { loadUser } from "redux-oidc";
import { Provider as ReduxProvider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router'

import { ConsoleSpanExporter, SimpleSpanProcessor } from '@opentelemetry/tracing';
import { CollectorExporter } from '@opentelemetry/exporter-collector';
import { WebTracerProvider } from '@opentelemetry/web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { B3Propagator } from '@opentelemetry/core';
import { FetchPlugin } from '@opentelemetry/plugin-fetch';
import { Resource, SERVICE_RESOURCE } from '@opentelemetry/resources';

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { OidcProvider } from "redux-oidc";
import store, { history } from "./store";
import { userManager } from "./auth";

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ConnectedRouter history={history}>
        <OidcProvider userManager={userManager} store={store}>
          <App />
        </OidcProvider>
      </ConnectedRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

loadUser(store, userManager);

const provider = new WebTracerProvider({
  plugins: [
    new FetchPlugin({
      propagateTraceHeaderCorsUrls: [
        new RegExp(`${window.APP_CONFIG.apiBaseURL}.*`),
        new RegExp(`${window.APP_CONFIG.authority}.*`),
      ],
      clearTimingResources: true,
    })
  ],
  resource: Resource.createTelemetrySDKResource().merge(new Resource({
    [SERVICE_RESOURCE.NAME]: 'myaccount',
    [SERVICE_RESOURCE.VERSION]: window.APP_CONFIG.version,
  })),
});

if (window.APP_CONFIG.collectorURL) {
  provider.addSpanProcessor(new SimpleSpanProcessor(new CollectorExporter({
    url: window.APP_CONFIG.collectorURL,
    serviceName: 'myaccount',
  })));
}

provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
provider.register({
  contextManager: new ZoneContextManager(),
  propagator: new B3Propagator(),
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
