import React from "react";

const BlockTitle: React.FC = (props) => {
  return (
    <div className="text-gray-900 font-bold text-xl p-4 pb-2">
      {props.children}
    </div>
  );
};

export default BlockTitle;
