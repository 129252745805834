import React, { useState } from "react";
import { Redirect } from "react-router";
import { User } from "oidc-client";
import { CallbackComponent } from "redux-oidc";

import { userManager } from "./";

const Callback: React.FC = () => {
  const [user, setUser] = useState<User>();

  const errorCallback = (e: Error) => {
    throw e;
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={setUser}
      errorCallback={errorCallback}
    >
      <>
        {user && <Redirect to="/" />}
        <div>Redirecting...</div>
      </>
    </CallbackComponent>
  );
};

export default Callback;
