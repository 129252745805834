import React from "react";

const Home: React.FC = () => {
  return (
    <div>
      <div
        className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-8"
        role="alert"
      >
        <p className="font-bold">Information</p>
        <p>La plateforme Tenoco est actuellement en bêta.</p>
        <p>
          Durant cette phase, les périodes de maintenances ne seront pas
          nécessairement annoncées à l'avance.
        </p>
        <p>
          Certains paramètres peuvent ne pas être configurables et certains
          services peuvent être momentanément indisponibles.
        </p>
      </div>
      <h1>Bienvenue</h1>
      <p>Gérez tout ce qui concerne votre compte.</p>
    </div>
  );
};

export default Home;
