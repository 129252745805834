import * as t from "io-ts";
import { Decoder } from "io-ts/es6/Decoder";
import { Encoder } from "io-ts/es6/Encoder";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";

export const LoginType = t.union([
  t.literal("LOGIN_TYPE_UNKNOWN"),
  t.literal("LOGIN_TYPE_EMAIL"),
  t.literal("LOGIN_TYPE_MATRIX"),
]);

export type LoginType = t.TypeOf<typeof LoginType>;

export const BasePassword = t.intersection([
  t.type({
    type: LoginType,
    username: t.string,
    name: t.string,
  }),
  t.partial({
    description: t.string,
    expirationTime: t.union([t.null, DateFromISOString]),
  }),
]);
export type BasePassword = t.TypeOf<typeof BasePassword>;
export const BasePasswordDecoder = BasePassword as Decoder<BasePassword>;
export const BasePasswordEncoder = BasePassword as Encoder<
  unknown,
  BasePassword
>;

export const SavedPassword = t.intersection([
  BasePassword,
  t.type({
    id: t.number,
    creationTime: DateFromISOString,
  }),
]);
export type SavedPassword = t.TypeOf<typeof SavedPassword>;
export const SavedPasswordDecoder = SavedPassword as Decoder<SavedPassword>;
export const SavedPasswordEncoder = SavedPassword as Encoder<
  unknown,
  SavedPassword
>;

export const GeneratedPassword = t.intersection([
  SavedPassword,
  t.type({
    password: t.string,
  }),
]);
export type GeneratedPassword = t.TypeOf<typeof GeneratedPassword>;
export const GeneratedPasswordDecoder = GeneratedPassword as Decoder<
  GeneratedPassword
>;
export const GeneratedPasswordEncoder = GeneratedPassword as Encoder<
  unknown,
  GeneratedPassword
>;
