import React from "react";
import Security from "./pages/security";
import { Switch, Route, Redirect } from "react-router";

import AuthCallback from "./auth/callback";
import PersonalInfo from "./pages/personal-info";
import Services from "./pages/Services";
import Home from "./pages/Home";
import Base from "./components/layouts/Base";

function App() {
  return (
    <Base>
      <Switch>
        <Route path="/callback" component={AuthCallback} />
        <Route path="/personal-info" component={PersonalInfo} />
        <Route path="/services" component={Services} />
        <Route path="/security" component={Security} />
        <Route path="/name">
          <Redirect to="/personal-info/edit" />
        </Route>
        <Route path="/gender">
          <Redirect to="/personal-info/edit" />
        </Route>
        <Route path="/" component={Home} />
      </Switch>
    </Base>
  );
}

export default App;
