import React from "react";
import { FormField } from "@oryd/kratos-client/dist/model/formField";
import RenderUserSettingsField from "./RenderUserSettingsField";

interface UserSettingsField {
  fields?: FormField[];
  name: string;
  hidden?: boolean;
}

const UserSettingsField: React.FC<UserSettingsField> = (
  props: UserSettingsField
) => {
  if (!props.fields) {
    return null;
  }

  const matchingFields = props.fields?.filter((f) => f.name === props.name);
  if (matchingFields.length < 1) {
    return null;
  }

  const hide = props.hidden || false;

  const field = matchingFields[0];
  return <RenderUserSettingsField field={field} hidden={hide} />;
};

export default UserSettingsField;
