import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { SettingsRequest } from "@oryd/kratos-client/dist/model/models";
import { SettingsRequestUrl, getSettingsRequest } from "../../../api";
import Block from "../../../components/ui/Block";
import BlockTitle from "../../../components/ui/BlockTitle";
import BlockBody from "../../../components/ui/BlockBody";
import UserSettingsField from "../../../components/form/UserSettingsField";
import Submit from "../../../components/form/Submit";

const Password: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const requestId = new URLSearchParams(location.search).get("request");

  const [settingsRequest, setSettingsRequest] = useState<SettingsRequest>();

  useEffect(() => {
    (async () => {
      if (requestId) {
        try {
          const sr = await getSettingsRequest(requestId);
          if (!sr.id) {
            throw new Error("undefined id");
          }
          setSettingsRequest(sr);
        } catch (e) {
          console.error(e);
          sessionStorage.setItem("last-edit", "password");
          history.push(SettingsRequestUrl);
        }
      } else {
        sessionStorage.setItem("last-edit", "password");
        history.push(SettingsRequestUrl);
      }
    })();
  }, [requestId, history]);

  const pass = settingsRequest?.methods.password;
  const config = pass?.config;
  const action = config?.action;
  const method = config?.method;
  const fields = config?.fields || [];

  if (!requestId) {
    sessionStorage.setItem("last-edit", "password");
    return <Redirect to={SettingsRequestUrl} />;
  }

  return (
    <>
      <h1>Sécurité</h1>
      <Block>
        <BlockTitle>Mot de passe</BlockTitle>
        <BlockBody>
          <p>Entrez ici votre nouveau mot de passe pour le modifier.</p>
          <form action={action} method={method}>
            {fields.map((f) => (
              <UserSettingsField fields={fields} name={f.name} key={f.name} />
            ))}
            <Submit value="Modifier" />
          </form>
        </BlockBody>
      </Block>
    </>
  );
};

export default Password;
