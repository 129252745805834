import React from "react";

const Submit: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (
  props
) => {
  return (
    <input
      type="submit"
      className="ml-32 bg-primary text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer"
      {...props}
    />
  );
};

export default Submit;
