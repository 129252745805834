import React from "react";
import Header from "../ui/Header";
import Menu from "../ui/Menu";

const Base: React.FC = (props) => {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 ">
        <div className="menu">
          <Menu />
        </div>
        <div className="flex-1 p-4">{props.children}</div>
      </div>
    </div>
  );
};

export default Base;
