import React from "react";

const Block: React.FC = (props) => {
  return (
    <div className="w-full mb-4">
      <div className="border border-gray-400 bg-white rounded leading-normal">
        {props.children}
      </div>
    </div>
  );
};

export default Block;
