import { reducer as authReducer } from "redux-oidc";
import { combineReducers, Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import { LocationState, History } from "history";

import { State } from "./state";
import { Action } from "./actions";
import appPasswordsReducer from "./apppasswords/reducer";

const createRootReducer = <S = LocationState>(
  history: History<S>
): Reducer<State<S>, Action> =>
  combineReducers({
    auth: authReducer,
    router: connectRouter(history),
    apppasswords: appPasswordsReducer,
  });

export default createRootReducer;
