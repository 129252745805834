import React, { useEffect, useState } from "react";
import { SettingsRequest } from "@oryd/kratos-client/dist/model/models";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import Block from "../../components/ui/Block";
import BlockTitle from "../../components/ui/BlockTitle";
import BlockBody from "../../components/ui/BlockBody";
import { SettingsRequestUrl, getSettingsRequest } from "../../api";
import RenderUserSettingsField from "../../components/form/RenderUserSettingsField";
import UserSettingsField from "../../components/form/UserSettingsField";
import Submit from "../../components/form/Submit";

const Edit: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const requestId = new URLSearchParams(location.search).get("request");
  const lastEdit = sessionStorage.getItem("last-edit");

  const [settingsRequest, setSettingsRequest] = useState<SettingsRequest>();

  useEffect(() => {
    (async () => {
      if (requestId) {
        if (lastEdit === "password") {
          sessionStorage.removeItem("last-edit");
          history.push(`/security/password?request=${requestId}`);
        } else {
          try {
            const sr = await getSettingsRequest(requestId);
            if (!sr.id) {
              throw new Error("undefined id");
            }
            setSettingsRequest(sr);
          } catch (e) {
            console.error(e);
            history.push(SettingsRequestUrl);
          }
        }
      } else {
        history.push(SettingsRequestUrl);
      }
    })();
  }, [requestId, history, lastEdit]);

  const profile = settingsRequest?.methods.profile;
  const config = profile?.config;
  const action = config?.action;
  const method = config?.method;
  const fields = config?.fields;

  if (!requestId) {
    return <Redirect to={SettingsRequestUrl} />;
  }

  if (!settingsRequest) {
    return null;
  }

  const nameFields = ["csrf_token", "traits.name.first", "traits.name.last"];
  const genderFields = ["csrf_token", "traits.gender"];

  return (
    <div>
      <h1>Informations personnelles</h1>

      <Block>
        <BlockTitle>Nom</BlockTitle>
        <BlockBody>
          <form action={action} method={method}>
            {nameFields.map((f) => (
              <UserSettingsField fields={fields} name={f} key={f} />
            ))}
            {fields
              ?.filter((f) => !nameFields.includes(f.name))
              .map((f) => (
                <RenderUserSettingsField field={f} key={f.name} hidden={true} />
              ))}
            <Submit value="Sauvegarder" />
          </form>
        </BlockBody>
      </Block>

      <Block>
        <BlockTitle>Sexe</BlockTitle>
        <BlockBody>
          <form action={action} method={method}>
            {genderFields.map((f) => (
              <UserSettingsField fields={fields} name={f} key={f} />
            ))}
            {fields
              ?.filter((f) => !genderFields.includes(f.name))
              .map((f) => (
                <RenderUserSettingsField field={f} key={f.name} hidden={true} />
              ))}
            <Submit value="Sauvegarder" />
          </form>
        </BlockBody>
      </Block>
    </div>
  );
};

export default Edit;
