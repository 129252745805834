import React from "react";
import { NavLink } from "react-router-dom";

const Menu: React.FC = () => {
  return (
    <ul className="p-4">
      <li>
        <NavLink
          className="menu-item"
          exact
          activeClassName="menu-item--selected"
          to="/"
        >
          Accueil
        </NavLink>
      </li>

      <li>
        <NavLink
          className="menu-item"
          activeClassName="menu-item--selected"
          to="/personal-info"
        >
          Informations personnelles
        </NavLink>
      </li>

      <li>
        <NavLink
          className="menu-item"
          activeClassName="menu-item--selected"
          to="/services"
        >
          Services
        </NavLink>
      </li>

      <li>
        <NavLink
          className="menu-item"
          activeClassName="menu-item--selected"
          to="/security"
        >
          Sécurité
        </NavLink>
      </li>
    </ul>
  );
};

export default Menu;
