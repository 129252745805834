import * as t from "io-ts";
import * as E from "io-ts/es6/Encoder";
import * as D from "io-ts/es6/Decoder";
import * as m from "./models";

type Methods = "GET" | "POST" | "DELETE";
type Path<R> = string | ((req: R) => string);

export type Route<Req, Res> = {
  method: Methods;
  path: Path<Req>;
  request?: E.Encoder<unknown, Req>;
  response: D.Decoder<Res>;
};

const route = <Req, Res>(
  method: Methods,
  path: Path<Req>,
  request: E.Encoder<unknown, Req> | undefined,
  response: D.Decoder<Res>
): Route<Req, Res> => ({
  method,
  path,
  request,
  response,
});

export const GeneratePassword = route(
  "POST",
  "/v1/apppasswords",
  m.BasePasswordEncoder,
  m.GeneratedPasswordDecoder
);

export const ListPassword = route(
  "GET",
  "/v1/apppasswords",
  undefined,
  D.type({ passwords: D.array(m.SavedPasswordDecoder) })
);

export const DeletePassword = route(
  "DELETE",
  (id: number) => `/v1/apppasswords/${id}`,
  t.number,
  D.type({})
);
