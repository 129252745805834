import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Edit from "./Edit";

const PersonalInfo: React.FC = () => {
  return (
    <Switch>
      <Route path="/personal-info/edit" component={Edit} />
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default PersonalInfo;
